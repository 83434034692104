import {
  CertificateSeason,
  CertificateType,
} from "authory-api-types/dist/enums";

export const getCertificateTypeLabel = (certificateType: CertificateType) => {
  switch (certificateType) {
    case CertificateType.prolificExpert:
      return "Prolific Expert";
    case CertificateType.experienceLeader:
      return "Experience Leader";
    case CertificateType.humanWriter:
      return "Human Writer";
    case CertificateType.longFormProfessional:
      return "Long Form Professional";
    case CertificateType.socialMediaSpecialist:
      return "Social Media Specialist";
    default:
      return certificateType;
  }
};

export const getCertificateSeasonLabel = (
  certificateSeason: CertificateSeason
) => {
  switch (certificateSeason) {
    case CertificateSeason.spring2023:
      return "Spring 2023";
    case CertificateSeason.fall2023:
      return "Fall 2023";
    case CertificateSeason.spring2024:
      return "Spring 2024";
    case CertificateSeason.fall2024:
      return "Fall 2024";
    default:
      return certificateSeason;
  }
};

export const getCertificateSeasonAwardDayLabel = (
  certificateSeason: CertificateSeason
) => {
  switch (certificateSeason) {
    case CertificateSeason.spring2023:
      return "1st May 2023";
    case CertificateSeason.fall2023:
      return "26th October 2023";
    case CertificateSeason.spring2024:
      return "3rd June 2024";
    case CertificateSeason.fall2024:
      return "9th December 2024";
    default:
      return certificateSeason;
  }
};

export const getCertificateColor = (certificateType: CertificateType) => {
  switch (certificateType) {
    case CertificateType.prolificExpert:
      return "#FAB43A";
    case CertificateType.experienceLeader:
      return "#F0426E";
    case CertificateType.humanWriter:
      return "#34C5CC";
    case CertificateType.longFormProfessional:
      return "#ABC94F";
    case CertificateType.socialMediaSpecialist:
      return "#7151F6";
    default:
      return certificateType;
  }
};

export const getCertificatePreviewBackgroundColor = (
  certificateType: CertificateType
) => {
  switch (certificateType) {
    case CertificateType.prolificExpert:
      return "#F08000";
    case CertificateType.experienceLeader:
      return "#ED5E82";
    case CertificateType.humanWriter:
      return "#0095B2";
    case CertificateType.longFormProfessional:
      return "#6CA51D";
    case CertificateType.socialMediaSpecialist:
      return "#8466FF";
    default:
      return certificateType;
  }
};

export const getCertificatePreviewVectorColor = () => {
  return "white";
};
